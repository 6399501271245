<template>
  <div class="review-list">
    <div class="info">
      <span class="total">전체 후기: {{ state.review.total }}</span>
      <a href="/download/admin/reviews/excel" target="_blank" rel="noopener noreferrer" class="btn btn-default" title="클릭하여 다운로드">
        <i class="fa fa-download"></i>
        <span>엑셀 다운로드</span>
      </a>
    </div>
    <ReviewCard
        v-for="(r,idx) in state.review.list" :key="idx"
        :skeleton="r.skeleton || !state.review.loaded"
        :content="r.content"
        :createDate="r.createDate"
        :email="r.email"
        :hideInvestorNameYn="'N'"
        :reviewId="r.id"
        :replies="r.replies"
        :memberSeq="r.memberSeq"
        :memberName="r.memberName"
        page="admin"
        :photos="r.photos"
        :selectedTags="r.selectedTags"
        :projectName="r.projectName"
        :projectSeq="r.projectSeq"
        :blindFlag="r.blindFlag"
    />
    <PaginationJpa :pageable="state.review.pageable" :total="state.review.total" :change="load" v-if="state.review.pageable.size" className="btn-primary"/>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import ReviewCard from "@/components/ReviewCard";
import PaginationJpa from "@/components/PaginationJpa";
import http from "@/scripts/http";

function Component(initialize) {
  this.name = "pageAdminReviewReviewList";
  this.initialize = initialize;
}

export default defineComponent({
  components: {PaginationJpa, ReviewCard},
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      load();
    });

    const state = reactive({
      review: {
        loaded: false,
        list: [],
        pageable: {
          page: 0,
          size: 0,
        },
        total: 0,
      },
      args: {
        page: 0,
        size: 30,
      },
    });

    const load = (page) => {
      state.args.page = page || 0;
      state.review.loaded = false;

      for (let i = 0; i < 5; i += 1) {
        state.review.list.push({
          skeleton: true,
          content: "Wait a moment",
          createDate: "0000-00-00 00:00",
          memberName: "Wait",
          selectedTags: "wait,wait,wait,wait,wait",
        });
      }

      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });

      http.get("/api/admin/reviews", state.args).then(({data}) => {
        state.review.loaded = true;
        state.review.list = data.body.content;
        state.review.pageable = data.body.pageable;
        state.review.total = data.body.total;
      });
    };

    return {component, state, load};
  }
});
</script>

<style lang="scss" scoped>
.review-list {
  .info {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: $px-20;
    margin-bottom: $px10;

    .total {
      font-size: $px12;
      display: inline-block;
      margin-right: $px10;
      vertical-align: bottom;
    }

    .btn {
      font-size: $px12;
      height: $px43;
      width: auto;
      padding-left: $px15;
      padding-right: $px15;

      i {
        margin-right: $px7;
      }
    }
  }
}
</style>